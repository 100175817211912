html,
body,
#root {
    height: 100vh;
    margin: 0px;
    cursor: url('/public/cursor_seta.svg'), auto;
}

@font-face {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Regular"),
        url("./../font/Poppins-Regular.woff") format("woff");
}

@font-face {
    font-family: "Dela Gothic Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Dela Gothic Regular"),
        url("./../font/DelaGothicOne-Regular.ttf") format("woff");
}

@font-face {
    font-family: "Poppins ExtraLight";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins ExtraLight"),
        url("../font/Poppins-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "Poppins Light";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Light"), url("../font/Poppins-Light.woff") format("woff");
}

@font-face {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins SemiBold"),
        url("../font/Poppins-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Bold"), url("../font/Poppins-Bold.woff") format("woff");
}

@font-face {
    font-family: "Inter Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Inter Regular"), url("../font/Inter-Regular.ttf") format("truetype");
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #EC64A1;
    border-radius: 5px;
    cursor: url('/public/cursor_seta.svg'), auto;
}

.scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    overflow-x: hidden;
}

::-webkit-scrollbar-corner {
    cursor: url('/public/cursor_seta.svg'), auto;
}